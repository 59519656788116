<template>
  <div>
    <div class="px-2">

      <div class="row">

        <div class="w-100 mr-1">
          <div class="d-flex justify-content-end pb-2">
            <button class="btn btn-primary ml-2" @click="addNew()">Add New</button>

          </div>
        </div>

        <div v-for="(contactRequest,index) in contactRequest" :key="index" class="d-flex w-100 align-items-end mb-1">

          <div class="w-100 mr-1">
            <input type="text" v-model="contactRequest.value" class="form-control" id="contactRequestForList" placeholder="Contact Request For List">

          </div>
          <div>
            <button @click="removeRequest(contactRequest.id)" class="btn"><i class='bx bx-x'></i></button>
          </div>

        </div>

        <div class="d-flex w-100 align-items-center justify-content-end px-2">
          <div>
            <button @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingContactRequestForList",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['contactRequestForList'],
  data() {
    return {
      contactRequest: [],
    };
  },
  computed: {
    ...mapGetters({
      settingContactRequestForList: 'appSettings/settingContactRequestForList',
    }),



  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    addNew() {
      this.contactRequest.push({
        id: this.contactRequest.length > 0 ? (this.contactRequest[this.contactRequest.length - 1].id + 1) : 1,
        value: "",
       });

    },

    removeRequest(contactRequestId) {
      if (this.contactRequest.length === 1) {
        this.showToastMessage({
          type: 'error',
          message: `Can't delete`
        });
        return;
      }
      this.contactRequest = this.contactRequest.filter((contactRequest) => contactRequest.id !== contactRequestId);
    },

    async updateSettings() {
      const isAllContactRequestValueIsFilledUp = this.contactRequest.every((item)=> {
        return item.value ? true : false;
      })
      if (!isAllContactRequestValueIsFilledUp){
        const toastObj = {
          message: 'Please insert value for newly added items ',
          type: 'error'
        };

        this.showToastMessage(toastObj);
        return ;
      }
      let dataObj = {
        id: this.settingContactRequestForList.id,
        data: {
          type: this.settingContactRequestForList.type,
          key: this.settingContactRequestForList.key,
          value: this.contactRequest.map(item => item.value)
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Contact request for list updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
  mounted() {
    this.contactRequest = (this.contactRequestForList?.value ?? [] ).map((item,index) => ({value:item,id:index}))
  }
}
</script>

<style scoped>

</style>