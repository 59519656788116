<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="contact-setting-wrapper">

          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">Contact/List</h4>
          </div>
          <div class="accordion" id="accordionContact">

            <div class="card">
              <div class="card-header" id="settingContactReferencePrefixHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingContactReferencePrefixCollapse" aria-expanded="true" aria-controls="collapseContactReferencePrefix">
                  <span class="text-capitalize contact-title">Contact Reference Prefix</span>
                </button>
              </div>
              <div id="settingContactReferencePrefixCollapse" class="collapse" aria-labelledby="settingContactReferencePrefixHeading" data-parent="#accordionContact">
                <div class="card-body">
                  <SettingContactReferencePrefix :contact-reference-prefix="this.contactReferencePrefix" />
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="settingContactReferenceSuffixHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingContactReferenceSuffixCollapse" aria-expanded="true" aria-controls="collapseContactReferenceSuffix">
                  <span class="text-capitalize contact-title">Contact Reference Suffix</span>
                </button>
              </div>
              <div id="settingContactReferenceSuffixCollapse" class="collapse" aria-labelledby="settingContactReferenceSuffixHeading" data-parent="#accordionContact">
                <div class="card-body">
                  <SettingContactReferenceSuffix :contact-reference-suffix="this.contactReferenceSuffix" />
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="settingContactRequestForListHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingContactRequestForListCollapse" aria-expanded="true" aria-controls="collapseContactRequestForList">
                  <span class="text-capitalize contact-title">Contact Request For List</span>
                </button>
              </div>
              <div id="settingContactRequestForListCollapse" class="collapse" aria-labelledby="settingContactRequestForListHeading" data-parent="#accordionContact">
                <div class="card-body">
                  <SettingContactRequestForList v-if="this.contactRequestForList?.value?.length > 0" :contact-request-for-list="this.contactRequestForList" />

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

    <script>
      import AppLayout from "@/layouts/backEnd/AppLayout";
      import {mapActions, mapGetters} from "vuex";
      import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
      import Loader from "@/components/backEnd/mixins/Loader";
      import SettingContactReferencePrefix from "@/views/backEnd/settings/contact/includes/SettingContactReferencePrefix";
      import SettingContactReferenceSuffix from "@/views/backEnd/settings/contact/includes/SettingContactReferenceSuffix";
      import SettingContactRequestForList from "@/views/backEnd/settings/contact/includes/SettingContactRequestForList";

      export default {
        name: "SettingContactList.vue",
        mixins: [ShowToastMessage, Loader],
        components: {
          AppLayout,
          SettingContactReferencePrefix,
          SettingContactReferenceSuffix,
          SettingContactRequestForList,
        },
        data() {
          return {
            getSettingsParams: {
              type: ["contact"],
              key: [
                'contact_reference_prefix',
                'contact_reference_suffix',
                'contact_request_for_list',
              ],
            },
          }
        },
        computed: {
          ...mapGetters({
            contactReferencePrefix: 'appSettings/settingContactReferencePrefix',
            contactReferenceSuffix: 'appSettings/settingContactReferenceSuffix',
            contactRequestForList: 'appSettings/settingContactRequestForList',
          }),
        },

        methods: {
          ...mapActions({
            getSettings: "appSettings/getSettings",
          }),
          async getSettingList() {
            await this.getSettings(this.getSettingsParams);
          },
        },
        async mounted() {
          await this.loader(true);
          await this.getSettingList();
          await this.loader(false);
        },

      }
    </script>

<style>
.contact-wrapper .accordion .card{
  overflow: visible !important;
}
.contact-title{
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>